@import '~@common/style/base/_variables.less';
@import '~@common/style/base/_reset.less';
@import '~@common/style/base/_space.less';
@import '~@common/style/base/_font.less';
@import '~@common/style/base/_flex.less';
@import '~@common/style/base/_base.less';
@import './color.less';

body {
  .ant-pro-global-header-collapsed-button {
    margin-left: 4px;
    margin-right: 16px;
  }

  .ant-descriptions-item-content {
    > .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-menu-title-content {
    > span {
      display: block;
    }
  }

  // 将无边框且只读/禁用的input内边距去掉,展现纯文本效果
  .ant-input-number-borderless input[readonly],
  .ant-input-number-borderless input[disabled],
  .ant-input-disabled.ant-input-borderless,
  .ant-select-borderless[readonly] .ant-select-selector {
    padding-left: 0;
    padding-right: 0;
    color: unset;
  }
  .ant-input-affix-wrapper-disabled.ant-input-affix-wrapper-borderless,
  .ant-input-number-borderless.ant-input-number-disabled,
  .ant-select.ant-select-borderless.ant-select-disabled .ant-select-selector {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: unset;
  }
  .ant-select.ant-select-borderless.ant-select-disabled .ant-select-arrow {
    display: none;
  }

  .ant-list-items {
    .ant-form-item {
      margin-bottom: 0;/* 订单详情费用列表样式 */
    }
  }
  
  .dialog-bg {
    z-index: 999;
  }
}

.text-style-input-container {
  .ant-input-number-disabled,
  .ant-input-affix-wrapper-disabled {
    border: none;
    background: transparent;

    &,
    .ant-input[disabled] {
      color: unset;
    }
  }
}

.text-style-list-container {
  .ant-space {
    + .ant-space {
      margin-top: 12px;
    }
  }

  .ant-list-item {
    > * {
      + * {
        margin-left: 10px;
      }
    }

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }
    .anticon-minus-circle {
      padding: 8px;
    }

    .ant-list-item-action {
      > li {
        padding: 0;
        + li {
          padding-left: 8px;
        }
      }
    }
  }
}
